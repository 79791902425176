import { useQueryClient } from '@tanstack/react-query';
import { patchClient, postClient } from 'src/api/restClient';
import { UseMutateHook } from 'src/api/types';
import useMutate from 'src/api/useMutate';
import { SaveCartAddressFormValues } from '../types';
import { DeliveryAddressQueryKeysEnum } from './queries';
import { ApiAddressPayload, ApiUserAddress } from './types';
import { mapZone } from 'src/modules/address/types';

interface UserAddressVariables {
  addressId: string;
  values: SaveCartAddressFormValues;
  country: {
    odooId: number;
    arName: string;
    name: string;
  };
  city: mapZone;
  area: mapZone;
  areaExternalId: number;
  latitude: number;
  longitude: number;
}

export const useMutateUserAddress: UseMutateHook<
  ApiUserAddress | ApiUserAddress[],
  UserAddressVariables
> = () => {
  const queryClient = useQueryClient();
  return useMutate(
    async ({
      addressId,
      values,
      city,
      country,
      area,
      latitude,
      longitude,
      areaExternalId,
    }) => {
      const payload: ApiAddressPayload = {
        addressDetails: {
          apartment: values.address.ApartmentNo,
          building: values.address.building,
          floor: values.address.flour,
          villa: values.address.villa,
        },
        residenceType: values?.address?.resident,
        slug: values?.address?.label,
        city,
        country,
        area,
        areaExternalId,
        longitude,
        latitude,
      };
      if (addressId) {
        const data: ApiUserAddress = await patchClient(
          `/users/v2/address/${addressId}`,
          payload
        );
        return data;
      } else {
        const data: ApiUserAddress[] = await postClient(
          '/users/v2/address/',
          payload
        );
        return data;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [DeliveryAddressQueryKeysEnum.deliveryAddressList],
        });
        queryClient.invalidateQueries({
          queryKey: [DeliveryAddressQueryKeysEnum.deliveryAddress],
        });
      },
    }
  );
};
