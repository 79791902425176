import { Country } from 'src/types';

export const COUNTRIES: Record<'AE' | 'QA' | 'OM' | 'SA', Country> = {
  AE: {
    currency: 'AED',
    vat: true,
    phoneCode: '+971',
    flagImage: '/flags/AE.svg',
    disabled: false,
    supportPhone: '+9710504427136',
    cities: [
      'Abu Dhabi',
      'Al Ain',
      'Dubai',
      'Sharjah',
      'Ajman',
      'Umm Al Quwain',
      'Fujairah',
      'Ras Al Khaimah',
    ],
  },
  QA: {
    currency: 'QAR',
    vat: false,
    phoneCode: '+974',
    disabled: false,
    flagImage: '/flags/QA.svg',
    supportPhone: '+97470001136',
    cities: [
      'AL KHOR',
      'AL SHAMAL',
      'UMM SALAL',
      'WEST BAY',
      'AL RAYYAN',
      'DOHA CENTER',
      'ABU HAMOUR',
      'OLD AIRPORT',
      'AL WAKRAH',
    ],
  },
  OM: {
    currency: 'OMR',
    vat: false,
    phoneCode: '+968',
    disabled: true,
    flagImage: '/flags/OM.svg',
  },
  SA: {
    currency: 'SAR',
    vat: true,
    phoneCode: '+966',
    disabled: true,
    flagImage: '/flags/SA.svg',
  },
};
