import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';

import { postClient } from 'src/api/restClient';
import { UseMutateHook } from 'src/api/types';
import useMutate from 'src/api/useMutate';
import { DeliveryAddressQueryKeysEnum } from 'src/modules/deliveryAddress/api/queries';
import appendPhoneCode from 'src/utils/appendPhoneCode';

import { useUserContext } from '../providers/UserProvider';
import { PhoneFormValues, User } from '../types';
import {
  ApiCheckReferral,
  ApiConfirmOtp,
  ApiConfirmOtpPayload,
  ApiLoginData,
  ApiLoginPayload,
  ApiSignUpPayload,
} from './types';
import { ApiProductInCart } from 'src/modules/cart/api/types';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useMutateAddToCart } from 'src/modules/cart/api/mutations';
import { updateCart } from 'src/redux/cart/cartSlice';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { getUserCurrency } from 'src/utils/getUserCurrency';
import { COUNTRIES_OPTIONS } from 'src/modules/address/constants';
import checkUserCart from '../utils/checkUserCart';
import useNavigator from 'src/hooks/useNavigator';
import checkValidAddresses from '../utils/checkValidAddresses';
import useCheckForFreeDelivery from 'src/modules/cart/hooks/useCheckForFreeDelivery';
interface LoginVariables {
  phoneNumber: PhoneFormValues;
}

export const useLogin: UseMutateHook<ApiLoginData, LoginVariables> = () => {
  return useMutate(
    ({ phoneNumber }) => {
      const payload: ApiLoginPayload = {
        phoneNumber: appendPhoneCode(phoneNumber),
      };

      return postClient(`/users/login/`, payload);
    },
    {
      disableErrorHandling: true,
    }
  );
};

interface SignUpVariables {
  phoneNumber: PhoneFormValues;
  fullName: string;
  email: string;
  countryExternalId: number;
  referralCode: number;
}

export const useSignUp: UseMutateHook<ApiLoginData, SignUpVariables> = () => {
  return useMutate(
    ({ phoneNumber, fullName, email, countryExternalId, referralCode }) => {
      const payload: ApiSignUpPayload = {
        phoneNumber: appendPhoneCode(phoneNumber),
        fullName,
        email,
        countryExternalId,
        customerPlatform: 'website',
        referralCode,
      };
      return postClient(`/users/`, payload);
    }
  );
};

interface ConfirmOtpVariables {
  phoneNumber: PhoneFormValues;
  otp: string[];
}

export const useConfirmOtp: UseMutateHook<
  ApiConfirmOtp,
  ConfirmOtpVariables
> = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const cart = useSelector((state: RootState) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigator();

  const { mutate: mutateCart } = useMutateAddToCart();
  const { userData: user, instructions } = useUserContext();
  const { userLocation, areaPrice, userCountry } = useLocationContext();
  const freeDeliveryCharge = useCheckForFreeDelivery();

  const isRegisterRoute = router.pathname === '/signup';
  const isLoginRoute = router.pathname === '/login';
  const isVerficationRoute = router.pathname === '/verification';

  const productsInCart: ApiProductInCart[] = cart.map(item => ({
    productId: item.id,
    setQty: item.amount,
  }));
  const [country] = COUNTRIES_OPTIONS.filter(
    country => country.code === userCountry
  );
  const companyId = country.companyId as number;

  return useMutate(
    async ({ phoneNumber, otp }) => {
      const payload: ApiConfirmOtpPayload = {
        phoneNumber: appendPhoneCode(phoneNumber),
        otp: otp.join(''),
      };
      const data = await postClient('/users/confirm/', payload);

      const userData: any = data;
      const user: User = {
        id: userData.user.id.toString(),
        fullName: userData.user.fullName,
        email: userData.user.email,
        phoneNumber: userData.user.phoneNumber,
        currency: getUserCurrency(userData.user.countryExternalId),
        countryId: userData.user.countryExternalId.toString(),
      };
      return {
        user,
        accessToken: userData.accessToken,
        draftOrderId: userData.user.draftOrderId,
        referralCode: userData?.user?.extraInfo?.referralCode,
      };
    },
    {
      onSuccess: async userData => {
        user(userData);

        const hasReferralCode = !!userData?.referralCode;
        const hasValidAddress = await checkValidAddresses(
          userLocation?.locationIds,
          userLocation?.country
        );
        const { userCart, activeCart } = await checkUserCart(
          isVerficationRoute,
          productsInCart
        );

        !!userCart?.[0] &&
          mutateCart(
            {
              cart: userCart,
              saleOrderId: activeCart?.orderId,
              locationId: userLocation?.locationIds,
              customerId: parseInt(userData?.user?.id),
              instruction: instructions,
              countryId: userLocation?.country,
              companyId,
              deliveryCharges:
                freeDeliveryCharge !== undefined
                  ? freeDeliveryCharge
                  : areaPrice,
            },
            {
              onSuccess: data => {
                if (data?.data?.orderDetails?.cartDetails?.cartLines) {
                  const updatedCart =
                    data?.data?.orderDetails?.cartDetails?.cartLines?.map(
                      item => ({
                        ...item,
                        amount: item?.quantity,
                      })
                    );
                  dispatch(updateCart(updatedCart));
                }
              },
            }
          );
        // remove address id
        delete router?.query?.id;

        hasReferralCode && isRegisterRoute
          ? navigate('/referral/success')
          : isLoginRoute || (isRegisterRoute && !cart?.[0])
          ? router.back()
          : isRegisterRoute && !!cart?.[0]
          ? navigate('/address', { replace: true })
          : isVerficationRoute && hasValidAddress
          ? navigate('/payment', {
              query: { action: 'change-address' },
              replace: true,
            })
          : navigate('/address', { replace: true });

        queryClient.invalidateQueries({
          queryKey: [DeliveryAddressQueryKeysEnum.deliveryAddressList],
        });
        queryClient.invalidateQueries({
          queryKey: [DeliveryAddressQueryKeysEnum.deliveryAddress],
        });
      },
      disableErrorHandling: true,
    }
  );
};

interface MutateCheckUserReferralCodeVariables {
  referralCode: number;
}

export const useMutateCheckUserReferralCode: UseMutateHook<
  ApiCheckReferral,
  MutateCheckUserReferralCodeVariables
> = () => {
  return useMutate(async ({ referralCode }) => {
    const payload = { referralCode };
    const data: ApiCheckReferral = await postClient(
      `/users/check-referral/`,
      payload
    );
    return data;
  });
};
