import * as React from 'react';

import { useTranslation } from 'next-i18next';
import { PhoneFormValues } from 'src/modules/auth/types';

const usePhoneValidation = (code: PhoneFormValues['code']) => {
  const { t } = useTranslation('auth');

  const validations = React.useMemo(
    () => ({
      required: {
        value: true,
        message: t('phone.errors.required'),
      },
      minLength: {
        value: code === '+974' ? 8 : 9,
        message:
          code === '+974'
            ? t('phone.errors.length', { length: 8 })
            : t('phone.errors.length', { length: 10 }),
      },
      maxLength: {
        value: code === '+974' ? 8 : 9,
        message:
          code === '+974'
            ? t('phone.errors.length', { length: 8 })
            : t('phone.errors.length', { length: 10 }),
      },
      pattern: {
        value: code === '+971' ? /^5[0|2|4|5|6|8]/i : /^[0-9]/i,
        message: t('phone.errors.pattern'),
      },
    }),
    [code, t]
  );

  return validations;
};

export default usePhoneValidation;
