import { COUNTRIES_OPTIONS } from 'src/modules/address/constants';
import { PhoneCode } from 'src/types';

const getUserCountryCode = (countryId): PhoneCode => {
  const [userCountry] = COUNTRIES_OPTIONS.filter(
    country => country.id === countryId
  );
  return userCountry?.countryKey;
};

export default getUserCountryCode;
