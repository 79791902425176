import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputOption } from '@SnackatCafe/snackat-ui';

import { useTranslation } from 'next-i18next';
import usePhoneCodesOptions from 'src/hooks/usePhoneCodesOptions';
import { PhoneFormValues } from 'src/modules/auth/types';
import { PhoneCode } from 'src/types';
import { selectedOption } from 'src/utils';
import SelectInput from '@SnackatCafe/snackat-ui/dist/SelectInput';
import TextInput from '@SnackatCafe/snackat-ui/dist/TextInput';
import TextAlert from '@SnackatCafe/snackat-ui/dist/TextAlert';
import usePhoneValidation from 'src/modules/hooks/usePhoneValidation';
import { useRouter } from 'next/router';

interface PhoneInputProps {
  readOnlyNumber?: boolean;
  readOnlyCode?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = props => {
  const { readOnlyNumber = false, readOnlyCode } = props;

  const router = useRouter();
  const { t } = useTranslation('login');
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<{ phone: PhoneFormValues }>();

  const options = usePhoneCodesOptions();
  const validations = usePhoneValidation(watch('phone.code'));
  const isVerificationRoute = router.pathname === '/verification';
  return (
    <>
      <div className="mb-2 font-semibold md:text-base">
        {isVerificationRoute ? (
          <>
            {t('verification.phone.label')}
            <div className="text-xs font-light md:text-sm">
              {t('verification.phone.subtitle')}
            </div>
          </>
        ) : (
          t('phone.label')
        )}
      </div>
      <div
        className={`flex w-full items-start gap-x-2 rounded-full border text-left  ${
          errors.phone?.number
            ? 'border-red-500 focus:border-red-500'
            : 'border-gray-300 hover:border-secondary-500 focus:border-secondary-500'
        }`}
        dir="ltr"
      >
        <div className="my-auto w-16 rounded-l-full md:w-20">
          <SelectInput
            requiredAsterisk
            label={t('phone.code.label')}
            value={
              selectedOption(
                watch('phone.code'),
                options
              ) as InputOption<string>
            }
            onChange={(selected: InputOption<PhoneCode>) =>
              setValue('phone.code', selected.value)
            }
            options={options}
            disabled={readOnlyCode}
            className="rounded-l-full border-none "
            isLabelPersistent={false}
          />
        </div>
        <div className="w-full rounded-full bg-white py-2">
          <TextInput
            disabled={!watch('phone.code')}
            readOnly={readOnlyNumber}
            placeholder={
              watch('phone.code') === '+971' ? '5xxxxxxxx' : 'xxxxxxxx'
            }
            {...register('phone.number', validations)}
            className="  rounded-r-full border-y-0 border-l-2 border-r-0 !border-primary-50 focus:shadow-none"
            dir="ltr"
          />
        </div>
      </div>
      <TextAlert
        show={errors.phone?.number !== undefined}
        className="text-primary-500"
      >
        {errors.phone?.number?.message}
      </TextAlert>
    </>
  );
};

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
