import { getClient } from 'src/api/restClient';
import { ApiGeoData } from 'src/modules/address/api/types';
import { ApiUserAddress } from 'src/modules/deliveryAddress/api/types';
import getBranchId from 'src/modules/landing/utils/getBranchId';

const checkValidAddresses = async (locationIds, countryId) => {
  const addressList: ApiUserAddress[] = await getClient('/users/v2/address/');
  const data: ApiGeoData = await getClient(
    `/core/geo-data/?country_external_id=${countryId}`
  );
  const geoData = data.countryGeoData;

  const validAddresses =
    !!addressList &&
    addressList.some(address => {
      const lat = address.latitude;
      const lng = address.longitude;
      const { branchId } = getBranchId(lat, lng, geoData);
      let branch = branchId as any;
      if (typeof branch === 'string') {
        branch = branch.split(',').map(i => JSON.parse(i));
      }
      const addressBranchId =
        typeof branch === 'object' && branch !== null ? [...branch] : [branch];

      return JSON.stringify(addressBranchId) === JSON.stringify(locationIds);
    });
  return validAddresses;
};

export default checkValidAddresses;
