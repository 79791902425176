import { getClient } from 'src/api/restClient';
import { ApiProductInCart } from 'src/modules/cart/api/types';
import { ApiOrdersList } from 'src/modules/myOrders/api/types';

const checkUserCart = async (isVerficationRoute, productsInCart) => {
  const data: ApiOrdersList = await getClient('/odoo/order/list/?status=draft');
  const activeCart = data?.orderDetails?.at(-1);

  const productsToRemove: ApiProductInCart[] =
    activeCart?.cartDetails?.cartLines?.map(item => ({
      productId: item?.id,
      setQty: -1,
    }));

  const userCart = !isVerficationRoute
    ? productsInCart
    : productsToRemove
    ? [...productsToRemove, ...productsInCart]
    : productsInCart;

  return { userCart, activeCart };
};

export default checkUserCart;
