import { getClient } from 'src/api/restClient';
import { UseGetHook } from 'src/api/types';
import useGet from 'src/api/useGet';
import { ApiUserAddress } from './types';
import { DeliveryAddress } from '../types';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import useTranslateSavedAddress from '../hooks/useTranslateSavedAddress';

export enum DeliveryAddressQueryKeysEnum {
  deliveryAddress = 'delivery-address',
  deliveryAddressList = 'delivery-address-list',
}

export const useGetUserAddressList: UseGetHook<ApiUserAddress[], unknown> = (
  _,
  options
) => {
  const { language } = useTranslationContext();
  const checkAddressTranslation = useTranslateSavedAddress();
  return useGet(
    {
      queryKey: [
        DeliveryAddressQueryKeysEnum.deliveryAddressList,
        { language },
      ],
      queryFn: async () => {
        const data: ApiUserAddress[] = await getClient('/users/v2/address/');
        data?.map(address => checkAddressTranslation(address));
        return data;
      },
    },
    options
  );
};

interface UserAddressVariables {
  addressId: string;
  language: 'en' | 'ar';
}

export const useGetUserAddress: UseGetHook<
  DeliveryAddress,
  UserAddressVariables
> = ({ addressId, language }, options) => {
  const checkAddressTranslation = useTranslateSavedAddress();
  return useGet(
    {
      queryKey: [
        DeliveryAddressQueryKeysEnum.deliveryAddress,
        { addressId, language },
      ],
      queryFn: async () => {
        const data: ApiUserAddress = await getClient(
          `/users/v2/address/${addressId}/`
        );
        checkAddressTranslation(data);
        const values: DeliveryAddress = {
          city: data?.city,
          area: data?.area,
          lat: data?.latitude,
          lng: data?.longitude,
          address: {
            city: language === 'en' ? data?.city.name : data?.city?.arName,
            area: language === 'en' ? data?.area.name : data?.area?.arName,
            resident: data?.residenceType,
            villa: data?.addressDetails?.villa,
            building: data?.addressDetails?.building,
            ApartmentNo: data?.addressDetails?.apartment,
            flour: data?.addressDetails?.floor,
            label: data?.slug,
          },
        };
        return values;
      },
    },
    options
  );
};
