import * as React from 'react';

import { CountryCode, PhoneCode } from 'src/types';
import { COUNTRIES } from 'src/constants/countries';
import { InputOption } from '@SnackatCafe/snackat-ui/dist/types';
import { useTranslation } from 'next-i18next';

const usePhoneCodesOptions = (): InputOption<PhoneCode>[] => {
  const { t } = useTranslation('login');
  const options = React.useMemo(
    () =>
      Object.keys(COUNTRIES).map((value: CountryCode) => ({
        label: t(`phone.code.${COUNTRIES[value]?.phoneCode}`),
        value: COUNTRIES[value]?.phoneCode as PhoneCode,
        icon: (
          <div className="flex items-center">
            <div className="w-3.5 overflow-hidden rounded-sm bg-gray-300">
              <img
                className="w-full object-cover"
                src={COUNTRIES[value]?.flagImage}
              />
            </div>
          </div>
        ),
        disabled: COUNTRIES[value]?.disabled,
      })),
    []
  );

  return options;
};

export default usePhoneCodesOptions;
