import * as React from 'react';
import translateMapCityArea from 'src/modules/address/utils/translateMapCityArea';
import { useTranslationContext } from 'src/modules/app/providers/TranslationProvider';
import { useMutateUserAddress } from '../api/mutations';

const useTranslateSavedAddress = () => {
  const { language } = useTranslationContext();
  const { mutate: updateAddress } = useMutateUserAddress();

  const changeAddressLanguage = React.useCallback(
    async address => {
      try {
        if (
          (language === 'en' && !address?.city?.name) ||
          (language === 'ar' && !address?.city?.arName)
        ) {
          const { trCity, trArea } = await translateMapCityArea(
            {
              location: { lat: address?.latitude, lng: address?.longitude },
              language,
            },
            language
          );
          updateAddress({
            addressId: address?.id?.toString(),
            area: {
              name: language === 'en' ? trArea : address?.area?.name,
              arName: language === 'ar' ? trArea : address?.area?.arName,
            },
            city: {
              name: language === 'en' ? trCity : address?.city?.name,
              arName: language === 'ar' ? trCity : address?.city?.arName,
            },
            country: address?.country,
            areaExternalId: address?.areaExternalId,
            latitude: address?.latitude,
            longitude: address?.longitude,
            values: {
              address: {
                city: language === 'en' ? trCity : address?.city?.name,
                area: language === 'en' ? trArea : address?.area?.name,
                resident: address?.residenceType,
                ApartmentNo: address?.addressDetails?.apartment,
                building: address?.addressDetails?.building,
                flour: address?.addressDetails?.floor,
                label: address?.slug,
                villa: address?.addressDetails?.villa,
              },
            },
          });
        }
      } catch (error) {
        console.info(error);
      }
    },
    [language]
  );

  return changeAddressLanguage;
};

export default useTranslateSavedAddress;
