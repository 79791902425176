import * as React from 'react';
import { PencilIcon } from '@heroicons/react/solid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { RefreshIcon } from '@heroicons/react/outline';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import Form from '@SnackatCafe/snackat-ui/dist/Form';
import OtpInput from '@SnackatCafe/snackat-ui/dist/OtpInput';
import Alert from '@SnackatCafe/snackat-ui/dist/Alert';

import { ApiError } from 'src/api/types';
import { useErrorBoundaryContext } from 'src/modules/error/ErrorBoundaryProvider';
import useAlert from 'src/hooks/useAlert';
import appendPhoneCode from 'src/utils/appendPhoneCode';
import validateOtp from 'src/utils/validateOtp';

import { LoginConfirmOtpStepFormValues, Otp, PhoneFormValues } from '../types';
import { useConfirmOtp, useLogin } from '../api/mutations';

const OTP_LENGTH = 4;

interface OtpStepProps {
  phone: PhoneFormValues;
  setIsOtpStep: (val: boolean) => void;
}

const OtpStep: React.FC<OtpStepProps> = props => {
  const { phone, setIsOtpStep } = props;

  const { t } = useTranslation('login');

  const {
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginConfirmOtpStepFormValues>({
    defaultValues: {
      otp: new Array(OTP_LENGTH).fill(''),
    },
  });

  const { onMutationError } = useErrorBoundaryContext();
  const [alert, setAlert] = useAlert();

  const { mutate: login, isLoading: isLoginLoading } = useLogin();
  const { mutate: confirmOtp, isLoading: isConfirmOtpLoading } =
    useConfirmOtp();

  const handleChange = (otp: Otp) => {
    setValue('otp', otp);

    if (validateOtp(otp)) {
      onSubmit({ otp });
    }
  };

  const handleError = (error: ApiError) => {
    setAlert({
      status: 'error',
      message: onMutationError(error, { suppress: true }),
    });
  };

  const onSubmit: SubmitHandler<LoginConfirmOtpStepFormValues> = values => {
    confirmOtp(
      {
        otp: values.otp,
        phoneNumber: phone,
      },
      {
        onError: handleError,
      }
    );
  };

  const handleResend = () => {
    login(
      { phoneNumber: phone },
      {
        onSuccess: () => {
          setAlert({
            status: 'info',
            message: t('otp.resent'),
          });
        },
        onError: handleError,
      }
    );
  };

  return (
    <div className="p-2">
      <div className="text-center">
        <h1 className="text-xl font-bold text-primary-500">{t('otp.title')}</h1>
        <p className="text-xs text-gray-500">{t('otp.subtitle')}</p>
        <Button
          variant="text"
          className="text-gray-500 hover:text-gray-600"
          size="sm"
          rightIcon={PencilIcon}
          onClick={() => setIsOtpStep(false)}
          disabled={isLoginLoading || isConfirmOtpLoading}
        >
          <span className="tracking-wider" dir="ltr">
            {appendPhoneCode(phone)}
          </span>
        </Button>
      </div>

      <Alert show={!!alert.message} className="mb-4" status={alert.status}>
        {alert.message}
      </Alert>

      <div className="flex flex-col items-center">
        <Form className="mb-3 w-full" onSubmit={handleSubmit(onSubmit)}>
          <div dir="ltr" className="mb-3">
            <div className="mb-1 flex items-center justify-between">
              <label
                className="block text-sm font-semibold text-gray-700"
                htmlFor="otp"
              >
                {t('otp.title')}
              </label>
              <Button
                variant="text"
                className="text-gray-500 hover:text-gray-600"
                size="xs"
                rightIcon={RefreshIcon}
                onClick={handleResend}
                isLoading={isLoginLoading}
                disabled={isConfirmOtpLoading}
              >
                {t('otp.buttons.resend')}
              </Button>
            </div>

            <div className="mb-4">
              <OtpInput
                error={errors.otp}
                {...register('otp', {
                  validate: validateOtp,
                  required: true,
                })}
                value={watch('otp')}
                onChange={handleChange}
              />
            </div>
          </div>

          <Button
            variant="solid"
            type="submit"
            className="w-full rounded-full shadow-md shadow-primary-400/30 hover:bg-white hover:text-primary-500"
            isLoading={isConfirmOtpLoading}
            disabled={isLoginLoading}
          >
            {t('otp.buttons.verify')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default OtpStep;
