import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { User } from 'src/modules/auth/types';
import { ApiProduct } from 'src/modules/products/apis/types';
import { ApiCartItem } from 'src/modules/myOrders/api/types';
import { Product } from 'src/redux/cart/cartSlice';
import { app } from 'src/scripts/firebase';

const analytics = typeof window !== 'undefined' && getAnalytics(app);

class Analytics {
  static logLoginEvent(user: User) {
    logEvent(analytics, 'login', {
      id: user.id,
      name: user.fullName,
      phoneNumber: user.phoneNumber,
      currency: user.currency,
    });
    setUserId(analytics, user.id);
    setUserProperties(analytics, {
      phoneNumber: user.phoneNumber,
      userName: user.fullName,
    });
  }
  static logSignUpEvent(user: User) {
    logEvent(analytics, 'sign_up', {
      id: user.id,
      name: user.fullName,
      phoneNumber: user.phoneNumber,
      currency: user.currency,
    });
    setUserId(analytics, user.id);
    setUserProperties(analytics, {
      phoneNumber: user.phoneNumber,
      userName: user.fullName,
    });
  }

  static logViewItemEvent(item: ApiProduct, currency) {
    logEvent(analytics, 'view_item', {
      currency,
      value: item.price,
      items: [
        {
          item_id: `${item.id}`,
          item_name: item.name,
          price: item.price,
          brand: item.brandName,
        },
      ],
    });
  }

  static logAddToCartEvent(item: ApiProduct, currency) {
    logEvent(analytics, 'add_to_cart', {
      currency,
      value: item.price,
      items: [
        {
          item_id: `${item.id}`,
          item_name: item.name,
          price: item.price,
        },
      ],
    });
  }

  static logRemoveFromCartEvent(item: Product, currency) {
    logEvent(analytics, 'remove_from_cart', {
      currency,
      value: item.price,
      items: [
        {
          item_id: `${item.id}`,
          item_name: item.name,
          price: item.price,
        },
      ],
    });
  }

  static logPurchaseEvent(
    cartItems: ApiCartItem[],
    currency,
    paymentMethod,
    totalPrice,
    orderId
  ) {
    logEvent(analytics, 'purchase' as any, {
      currency,
      items: cartItems.map(item => ({
        item_id: `${item.id}`,
        item_name: item.name,
        price: item.price,
        quantity: item?.quantity,
      })),
      payment_type: paymentMethod,
      value: totalPrice,
      orderId,
    });
  }
}

export default Analytics;
